import React, { useEffect, useState } from 'react';

export const TheAppContext = React.createContext();

type SkillControl = { DisplayName: string; slug: string; activated: boolean };
const dummySkills: SkillControl[] = [
  { DisplayName: 'Frontend', slug: 'frontend', activated: true },
  { DisplayName: 'Backend', slug: 'backend', activated: true },
  { DisplayName: 'Other', slug: 'other', activated: true },
  { DisplayName: 'Creative', slug: 'creative', activated: true },
];
const AppContext = ({ children }) => {
  const [DOMContentLoaded, setDomContentLoaded] = useState(false);
  const [skillControl, setSkillControl] = useState<SkillControl[]>(dummySkills);
  const [menuOpen, setMenuOpen] = useState(false);
  const [boxShowing, setBoxShowing] = useState({
    visible: false,
    component: null,
  });

  // useEffect(() => {
  //   if (typeof window !== 'undefined') {
  //     if ((menuOpen || boxShowing.visible) && typeof window !== 'undefined') {
  //       return (document.querySelector('html').style.overflow = 'hidden');
  //     }
  //     document.querySelector('html').style.overflow = 'unset';
  //   }
  // }, [menuOpen, boxShowing.visible]);

  const [skillView, setSkillView] = useState<'list' | 'scrum'>('list');
  const load_callback = () => {
    setDomContentLoaded(true);
  };
  const toggleSkillView = () => {
    if (skillView === 'list') setSkillView('scrum');
    else setSkillView('list');
  };

  const handleSkillChange = (slug: string, activated: boolean) => {
    const allControls = [...skillControl];
    const controlToChange = allControls.find((s) => s.slug === slug)!;
    if (controlToChange) {
      controlToChange.activated = !activated;
      setSkillControl(allControls);
    }
  };

  return (
    <TheAppContext.Provider
      value={{
        handleSkillChange,
        skillControl,
        setSkillControl,
        menuOpen,
        setMenuOpen,
        boxShowing,
        setBoxShowing,
        DOMContentLoaded,
        setDomContentLoaded,
        load_callback,
        skillView,
        toggleSkillView,
        setSkillView,
      }}
    >
      {children}
    </TheAppContext.Provider>
  );
};

export default AppContext;
