import React from 'react';
import LightBox from '../components/Codes/LightBox';
import AppContext from '../provider/AppContext';
const LayoutWrap = ({ children }) => {
  return (
    <AppContext>
      <LightBox />
      {children}
    </AppContext>
  );
};

export default LayoutWrap;
