import { createTheme } from '@mui/material';
import { red } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      paper: '#2a292b',
      default: '#181818',
    },
    primary: {
      main: 'rgb(129, 75, 255)',
      light: '#9465ff',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#19857b',
    },
    success: {
      main: '#26e290',
    },
    error: {
      main: red.A400,
    },
  },
  typography: {
    body1: {
      fontFamily: 'Gravity',
    },
    h2: {
      fontFamily: 'Futura',
    },
    h3: {
      fontFamily: 'Futura',
    },
    h4: {
      fontFamily: 'Futura',
    },
    h5: {
      fontWeight: 200,
    },
  },
});

export default theme;
