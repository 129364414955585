exports.components = {
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-codes-index-tsx": () => import("./../../../src/pages/codes/index.tsx" /* webpackChunkName: "component---src-pages-codes-index-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-everything-js": () => import("./../../../src/pages/everything.js" /* webpackChunkName: "component---src-pages-everything-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pixels-index-tsx": () => import("./../../../src/pages/pixels/index.tsx" /* webpackChunkName: "component---src-pages-pixels-index-tsx" */),
  "component---src-pages-projects-index-tsx": () => import("./../../../src/pages/projects/index.tsx" /* webpackChunkName: "component---src-pages-projects-index-tsx" */),
  "component---src-pages-sounds-index-tsx": () => import("./../../../src/pages/sounds/index.tsx" /* webpackChunkName: "component---src-pages-sounds-index-tsx" */),
  "component---src-pages-words-article-index-js": () => import("./../../../src/pages/words/article/index.js" /* webpackChunkName: "component---src-pages-words-article-index-js" */),
  "component---src-pages-words-index-tsx": () => import("./../../../src/pages/words/index.tsx" /* webpackChunkName: "component---src-pages-words-index-tsx" */),
  "component---src-templates-single-music-tsx": () => import("./../../../src/templates/SingleMusic.tsx" /* webpackChunkName: "component---src-templates-single-music-tsx" */),
  "component---src-templates-single-project-tsx": () => import("./../../../src/templates/SingleProject.tsx" /* webpackChunkName: "component---src-templates-single-project-tsx" */)
}

