import './src/styles/global.css';

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const { pathname } = location;
  // list of routes for the scroll-to-top-hook
  const scrollToTopRoutes = [`/contact`];
  // if the new route is part of the list above, scroll to top (0, 0)
  if (scrollToTopRoutes.includes(pathname)) {
    console.log('stay');
    window.scrollTo(0, 0);
  }

  return false;
};
